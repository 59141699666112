

.levelSection {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,1);

    transition: ease-in-out 0.5s all;

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 

}


.levelSection input {

    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 200px;
    padding: 0px 80px;

    margin-top: 20px;
    background-color: rgba(0,0,0,1) !important;
    color: white;

    text-align: center;

    border-radius: 0px;
    border: 1px solid white;

    z-index: 100;


}


.interludeTextContainer {

    /* Pos */
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: calc(50% - 50px);

    width: 80%;
    max-width: 400px;
    height: auto;

    /* Text */
    color: rgba(255,255,255, 0.7);
    text-align: center;
    line-height: 100px;
    font-style: oblique;
    font-size: 20px;
    z-index: 3;
}

.levelTitle {
    font-size: 30px;
}


.interludeImgContainer {

    /* Pos */
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, calc(-50% - 100px));

    width: 80%;
    max-width: 800px;
    height: auto;
    max-height: calc(100vh - 400px);

    object-fit: contain;

}

.npcDialogCard {

    border: 1px dotted white;
    height: 150px;
    display: flex;

}

.npcDialogCard p {
    padding-left: 20px;
}

.dialogPositioner {
    position: absolute;
    bottom: 100px;
    width: 100%;
}


@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 

    } 
    100% {
        opacity: 1; 
     } 
} 


.fade-enter {
    opacity: 0.01;
}

.fade-enter .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit .fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.userChoiceCard {
    display: flex;
    align-items: center;
}

.userChoiceCard img {
    height: 100%;
}

.userChoiceCard button {
    height: 70px;
    padding: 10px;
    background-color: rgba(50,50,50);
    opacity: 0.7;
    margin-left: 20px;
    color: white;
    transition: ease-in-out 0.2s all;
}

.userChoiceCard button:hover {
    opacity: 1;
    cursor: pointer
}