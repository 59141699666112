

.home {
    position: relative;
    width: 100%;
    height: calc(100vh);
}

.locationWrapper {
    position: relative;
    /* width: 100%;
    height: 100%; */
    /* object-fit: contain; */
    /* top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-40%); */
}

.location {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-40%); */
}

.castle {

}

.lowerBanner {
    color: white;
    opacity: 1;
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    font-family: 'Almendra SC';

    background-color: rgba(0,0,0, 0.5);

    font-size: 100px;
}